import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import { hideCheckboxModal } from '../../../features/Offcanvas/customOrderDetailModalSlice'
import { setSharePrescriptionData } from '../../../features/Data/dataSlice'

const CheckboxModal = ({
    checkboxModalVisible,
    hideCheckboxModal,
    customOrderDetailData,
    setSharePrescriptionData
}) => {
    const [values, setValues] = useState(['Name', 'OrderDate']);
    const [shareDataObj, setShareDataObj] = useState({})
    const [textCopyValue, setTextCopyValue] = useState('')
    const [copyTextarea, showCopyTextarea] = useState(false)
    const handleChange = (event) => {
        if (event.target.checked) {
          setValues([...values, event.target.value]);
        } else {
          setValues(values.filter((value) => value !== event.target.value));
        }
    };
    useEffect(() => {
        let tempNewObj = {}
        tempNewObj.Items = []
        if (values.length > 0) {
            values.map((v,k) => {
                if (customOrderDetailData) {
                    Object.keys(customOrderDetailData).map((v1,k1) => {
                        if (v == v1) {
                            tempNewObj[v1] = customOrderDetailData[v1]
                        } else if (customOrderDetailData['OrderDetails'].hasOwnProperty(v)) {
                            tempNewObj[v] = customOrderDetailData?.OrderDetails[v]
                        }
                    })
                }
                if (v.includes('~')) {
                    if (!tempNewObj.Items.includes(v.split('~')[1])) {
                        tempNewObj.Items.push(v.split('~')[1])
                    }
                } else if (v.includes('Prescription')) {
                    tempNewObj[v] = true
                }
            })
        }
        setShareDataObj(tempNewObj)
    }, [values])
    const closeCheckboxModal = () => {
        setValues([])
        hideCheckboxModal()
    }
    let tempVendorMessage = ''
    const sharePrescription = () => {
        if (shareDataObj?.Name) {
            tempVendorMessage += 'Name: ' + shareDataObj?.Name + ' \n'
        } if (shareDataObj?.OrderDate) {
            tempVendorMessage += 'Date: ' + shareDataObj?.OrderDate + ' \n'
        } if (shareDataObj?.Phone) {
            tempVendorMessage += 'Phone: ' + shareDataObj?.Phone + ' \n'
        } if (shareDataObj?.Email) {
            tempVendorMessage += 'Email: ' + shareDataObj?.Email + ' \n'
        } if (shareDataObj?.Address) {
            tempVendorMessage += 'Address: ' + shareDataObj?.Address + ' \n'
        } if (shareDataObj?.Age) {
            tempVendorMessage += 'Age: ' + shareDataObj?.Age + ' \n'
        } if (shareDataObj?.Items) {
            shareDataObj?.Items.map((v,k) => {
                tempVendorMessage += `Item${k + 1}: ` + v + ' \n'
            })
        }
        setTextCopyValue(tempVendorMessage)
        hideCheckboxModal()
        setSharePrescriptionData({prescriptionData: shareDataObj, vendorMessage: tempVendorMessage})
        // showCopyTextarea(true)
    }
    // function copyText() {
    //     // const textarea = document.getElementById('copyTextareaValue');
    //     // textarea.select();
    //     // document.execCommand('copy');
    //     // alert('Text copied to clipboard');
    //     showCopyTextarea(false)
    //     // hideCheckboxModal()
    //     console.log(shareDataObj, textCopyValue)
    //     setSharePrescriptionData({prescriptionData: shareDataObj, vendorMessage: textCopyValue})
    // }
    return (
        <div>
            <Modal show={checkboxModalVisible} size={'lg'} centered={true} onHide={() => {closeCheckboxModal()}} onEntered={() => {setValues(['Name', 'OrderDate'])}}>
                <Modal.Header closeButton style={{padding: '15px 10px 15px 10px'}}>
                </Modal.Header>
                <Modal.Body class="pt-0 p-1">
                    <div class="modal-body p-0 mt-1">
                        {/* {!copyTextarea && */}
                            <>
                                <div class="input-list">
                                    <div class="listview-title mt-2 fs-6">Personal Detail</div>
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="name" value="Name" onChange={handleChange} checked={values.includes('Name')} />
                                        <label class="form-check-label fs-5" for="name">Name</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="date" value="OrderDate" onChange={handleChange} checked={values.includes('OrderDate')}  />
                                        <label class="form-check-label fs-5" for="date">Date</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="mobile" value="Phone" onChange={handleChange} checked={values.includes('Phone')} />
                                        <label class="form-check-label fs-5" for="mobile">Mobile</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="email" value="Email" onChange={handleChange} checked={values.includes('Email')} />
                                        <label class="form-check-label fs-5" for="email">Email</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="address" value="Address" onChange={handleChange} checked={values.includes('Address')} />
                                        <label class="form-check-label fs-5" for="address">Address</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="age" value="Age" onChange={handleChange} checked={values.includes('Age')} />
                                        <label class="form-check-label fs-5" for="age">Age</label>
                                    </div>
                                    <div class="listview-title mt-2 fs-6">Prescription</div>
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="prescription" value="GlassPrescription" onChange={handleChange} checked={values.includes('GlassPrescription')} />
                                        <label class="form-check-label fs-5" for="prescription">Glass Prescription</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="contactLenses" value="ContactLensesPrescription" onChange={handleChange} checked={values.includes('ContactLensesPrescription')} />
                                        <label class="form-check-label fs-5" for="contactLenses">Contact Lenses Prescription</label>
                                    </div>
                                    <div class="listview-title mt-2 fs-6">Items</div>
                                    {customOrderDetailData?.Items?.map((v,k) => {
                                        return <>
                                            {v.CustomSIze &&   
                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input" id={"glassDescription" + k} value={'Items~' + v.CustomSIze} onChange={handleChange} checked={values.includes('Items~' + v.CustomSIze)} />
                                                    <label class="form-check-label fs-5" for={"glassDescription" + k}>{v.CustomSIze}</label>
                                                </div>
                                            }
                                        </>
                                    })}
                                </div>
                                <div class="text-end me-1 mb-1">
                                    <button class="btn btn-success" onClick={() => {sharePrescription()}}><ion-icon name="arrow-redo"></ion-icon> Send</button>
                                </div>
                            </>
                        {/* } */}
                        {/* {copyTextarea &&
                            <div class="form-group boxed ps-2 pe-2">
                                <div class="input-wrapper">
                                    <label class="form-label d-flex justify-content-between align-items-center" for="copyTextareaValue">
                                        <span>Preview</span>
                                        <span class="btn btn-secondary btn-sm" onClick={() => {copyText()}}><ion-icon name="copy-outline"></ion-icon>Copy</span>
                                    </label>
                                    <textarea id="copyTextareaValue" rows="5" value={textCopyValue} onChange={(e) => {setTextCopyValue(e.target.value)}} class="form-control"></textarea>
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                            </div>
                        } */}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ customOrderDetail }) => {
    const {
        checkboxModalVisible,
        customOrderDetailData
    } = customOrderDetail
    return {
        checkboxModalVisible,
        customOrderDetailData
    }
  }
  
  const mapDispatchToProps = {
    hideCheckboxModal,
    setSharePrescriptionData
  }

export default connect(mapStateToProps, mapDispatchToProps)(CheckboxModal)