import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setDefaultPrinterName } from '../../../features/Data/dataSlice';
import { hidePrinterSettingModal } from '../../../features/Offcanvas/printerSettingModalSlice';

const BluetoothList = ({
    pairedDeviceArr,
    setDefaultPrinterName,
    hidePrinterSettingModal
}) => {
    console.log(pairedDeviceArr)
    const [defaultPrinter, setDefaultPrinter] = useState()
    useEffect(() => {
        const defaultPrinterAddress = localStorage.getItem('defaultPrinter')
        if (defaultPrinterAddress) {
            setDefaultPrinter(defaultPrinterAddress)
        }
    }, [defaultPrinter])
    const handleSetDefaultPrinter = (deviceData) => {
        console.log(deviceData)
        localStorage.setItem('defaultPrinter', deviceData.macAddress)
        localStorage.setItem('defaultPrinterName', deviceData.macAddress)
        setDefaultPrinter(deviceData.macAddress)
        setDefaultPrinterName({defaultPrinterName: deviceData.name})
        hidePrinterSettingModal()
    }
    return (
        <div>
            <div class="section full mt-1">
                <div class="row">
                    {pairedDeviceArr &&
                        <div class="col-12 col-md-6 mb-2">
                            <div class="card">
                                <h3 class="mb-0 pb-0 fw-normal fs-6" style={{padding: '10px 16px'}}>Paired Device</h3>
                                <ul class="listview image-listview flush">
                                    {pairedDeviceArr?.map((v,k) => {
                                        return <>
                                            <li onClick={() => {handleSetDefaultPrinter(v)}}>
                                                <div class="item">
                                                    {v.type == 1 &&
                                                        <ion-icon name="phone-portrait-outline" class="image text-secondary" style={{borderRadius: 'initial'}}></ion-icon>
                                                    }
                                                    {v.type == 2 &&
                                                        <ion-icon name="print-outline" class="image text-secondary" style={{borderRadius: 'initial'}}></ion-icon>
                                                    }
                                                    {v.type == 3 &&
                                                        <ion-icon name="tv-outline" class="image text-secondary" style={{borderRadius: 'initial'}}></ion-icon>
                                                    }
                                                    {v.type == 4 &&
                                                        <ion-icon name="ellipsis-vertical-outline" class="image text-secondary" style={{borderRadius: 'initial'}}></ion-icon>
                                                    }
                                                    <div class="in">
                                                        <div>
                                                            {v.name}
                                                            <footer class="text-secondary">Paired</footer>
                                                        </div>
                                                        {defaultPrinter == v.macAddress &&
                                                            <span class="text-muted">
                                                                Default
                                                            </span>
                                                        }
                                                    </div>
                                                </div>
                                            </li>
                                        </>
                                    })}
                                </ul>
                            </div>
                        </div>
                    }
                    {!pairedDeviceArr &&
                        <div class="col-12 col-md-12">
                            <div class="card p-2">
                                <h2 class="text-danger text-center">No Paired Device</h2>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ data }) => {
    const {
        pairedDeviceArr
    } = data
    return {
        pairedDeviceArr
    }
  }
  
const mapDispatchToProps = {
    setDefaultPrinterName,
    hidePrinterSettingModal
}

export default connect(mapStateToProps, mapDispatchToProps)(BluetoothList);