import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import { hideCustomOrderDetailModal, setOpticPrintBill } from '../../../features/Offcanvas/customOrderDetailModalSlice'
import axios from '../../../AxiosConfig'
import ShreeOptics from '../../images/logos/shree-optics.png'
import Moment from 'react-moment'
import moment from 'moment'
import html2canvas from 'html2canvas'
import { showCheckboxModal } from '../../../features/Offcanvas/customOrderDetailModalSlice'
import CheckboxModal from './CheckboxModal'
import { showImagePdfModal } from '../../../features/Offcanvas/imagePdfModalSlice'
import { showVendorConfirmationModal, hideVendorConfirmationModal } from '../../../features/Offcanvas/confirmationModalSlice'
import { setCustomOrderRender } from '../../../features/Data/dataSlice'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { showPrinterSettingModal } from '../../../features/Offcanvas/printerSettingModalSlice'

const CustomOrderDetailModal = ({
    customOrderDetailModalVisible,
    hideCustomOrderDetailModal,
    customOrderDetailData,
    setOpticPrintBill,
    showCheckboxModal,
    prescriptionData,
    showImagePdfModal,
    showVendorConfirmationModal,
    vendorConfirmationModalVisible,
    hideVendorConfirmationModal,
    setCustomOrderRender,
    customOrderRender,
    vendorMessage,
    buildType,
    showPrinterSettingModal,
    bluetoothStatus
}) => {
    const [imagePath, setImagePath] = useState("");
    const handlePrintBill = () => {
        setOpticPrintBill({opticPrintBill: true, opticPrintBillData: customOrderDetailData})
        hideCustomOrderDetailModal()
    }
    const [paddingStyle, setPaddingStyle] = useState({});
    let tempVendorMessage = ''
    
    const handleCheckboxModal = () => {
        showCheckboxModal({checkboxModalVisible: true})
        // hideCustomOrderDetailModal()
    }
    const captureDiv = async (tableType) => {
        setPaddingStyle({ padding: '20px' });

        if (tableType !== 'textOnly') {
            await new Promise(resolve => setTimeout(resolve, 100));

            const captureElement = document.getElementById(tableType);
            const tempVendorMessage = await vendorMessage
            const canvas = await html2canvas(captureElement);
            const imageData = canvas.toDataURL('image/png')
            canvas.toBlob( (blob) => {
                const file = new File([blob], 'measurments.png', { type: 'image/png' });
                // SHARE IMAGE AND CAPTION
                window.plugins.socialsharing.share(tempVendorMessage, null, imageData, null)
                

                // WEB SHARE
                // if (navigator.share) {
                //   try {
                //     const file = new File([blob], 'measurments.png', { type: 'image/png' });
                //     navigator.share({
                //       files: [file],
                //       title: tempVendorMessage,
                //       text: tempVendorMessage,
                //     });
                //   } catch (error) {
                //     console.error('Error sharing', error);
                //   }
                // } else {
                //   alert('Web Share API is not supported in your browser.');
                // }
            });
        } else {
            await new Promise(resolve => setTimeout(resolve, 100));
            const tempVendorMessage = await vendorMessage
            window.plugins.socialsharing.share(tempVendorMessage)
        }
        
        setPaddingStyle({});
    };

    const updatedNumber = customOrderDetailData?.Phone.replace(/^91/, '');
    useEffect(() => {
        if (prescriptionData) {
            if (prescriptionData?.GlassPrescription && !prescriptionData?.ContactLensesPrescription) {
                captureDiv('table1')
            } else if (prescriptionData?.ContactLensesPrescription && !prescriptionData?.GlassPrescription) {
                captureDiv('table2')
            } else if (prescriptionData?.GlassPrescription && prescriptionData?.ContactLensesPrescription) {
                captureDiv('bothTable')
            } else {
                captureDiv('textOnly')
                // window.open(`https://wa.me/91${updatedNumber}?text=${encodeURIComponent(tempVendorMessage)}`)
            }
        }
    }, [prescriptionData])
    const handleFullImage = (imagePath) => {
        if (imagePath) {
            setImagePath(imagePath)
        } else {
            setImagePath()
        }
    }
    const composeMessage = (type) => {
        let itemList = ''
        customOrderDetailData.Items.map((v,k) => {
            if (v.CustomSIze) {
                itemList += `Item ${k + 1} : ${v.CustomSIze} \n `
            }
        })
        const composedBillMessage = 'Hello Sir/Madam ' + customOrderDetailData?.Name + ', your order is placed successfully. Thank you for your purchase! \n\n Invoice No: ' + customOrderDetailData?.BillNo + '\n Name: ' + customOrderDetailData?.Name + '\n ' + itemList + '\n Total: ₹' + customOrderDetailData?.Subtotal + '\n Discount: ₹' + customOrderDetailData?.TotalDiscount + '\n Advance: ₹' + customOrderDetailData?.TotalPaidAmount + '\n Balance: ₹' + customOrderDetailData?.Balance + '\n\nGoogle Maps Link - https://goo.gl/maps/amEoNoeEpND9eC4w7'
        if (type == 'whatsapp') {
            // setOpticPrintBill({opticPrintBill: true, opticPrintBillData: customOrderDetailData, printType: 'whatsappImage'})
            const phoneNumber = '+91' + updatedNumber
            window.plugins.socialsharing.shareViaWhatsAppToPhone(phoneNumber, composedBillMessage, null, null, function() {console.log('share ok')})
            // window.open(`https://wa.me/91${updatedNumber}?text=${encodeURIComponent(composedBillMessage)}`)
        } else if (type == 'sms') {
            // const encodedMessage = encodeURIComponent(composedBillMessage);
            // const smsUrl = `sms:+${updatedNumber}?body=${encodedMessage}`;
            
            // const link = document.createElement('a');
            // link.href = smsUrl;
            
            // document.body.appendChild(link);
            // link.click();
            
            // document.body.removeChild(link);

            window.plugins.socialsharing.shareViaSMS(composedBillMessage, updatedNumber, function(msg) {console.log('ok: ' + msg)}, function(msg) {alert('error: ' + msg)})
        }
    }
    const shareBillMessage = (type) => {
        composeMessage(type)
    }
    const handleImagePdf = () => {
        showImagePdfModal({imagePdfModalVisible: true})
        hideCustomOrderDetailModal()
    }
    const orderPlaceMessage = () => {
        const OrderPlacedMessage = 'Hello Sir/Madam ' + customOrderDetailData.Name + ', your order is placed successfully. Your order no is ' + customOrderDetailData.BillNo + '. Thank you for your purchase! \n\n Shree Optics \nShop No. 3, Shree Namrata, Plot No. 1354 A/2,\nChatrapati Shivaji Maharaj Marg,\nPanvel - 410206.\nMobile No. 9819244417\n\nGoogle Maps Link - https://goo.gl/maps/amEoNoeEpND9eC4w7'
        const encodedMessage = encodeURIComponent(OrderPlacedMessage);
        const whatsappUrl = `https://wa.me/91${updatedNumber}?text=${encodedMessage}`;
        window.open(whatsappUrl, '_blank')
    }
    const orderReadyMessage = () => {
        const OrderReadyMessage = 'Hello Sir/Madam ' + customOrderDetailData.Name + ', your order is ready. Your order no is ' + customOrderDetailData.BillNo + '. \n\n Shree Optics \nShop No. 3, Shree Namrata, Plot No. 1354 A/2,\nChatrapati Shivaji Maharaj Marg,\nPanvel - 410206.\nMobile No. 9819244417\n\nGoogle Maps Link - https://goo.gl/maps/amEoNoeEpND9eC4w7'
        const encodedMessage = encodeURIComponent(OrderReadyMessage);
        const whatsappUrl = `https://wa.me/91${updatedNumber}?text=${encodedMessage}`;
        window.open(whatsappUrl, '_blank')
    }
    const closeModal = () => {
        if (customOrderDetailData?.OrderDetails?.VendorDate) {
            hideCustomOrderDetailModal()
        } else {
            showVendorConfirmationModal({vendorConfirmationModalVisible: true})
        }
    }
    const closeFirstConfirmationModal = () => {
        hideVendorConfirmationModal()
        hideCustomOrderDetailModal()
    }
    const sharedToVendorConfirm = () => {
        const vendorData = {"OrderID": customOrderDetailData?.OrderID,"VendorDate": moment(new Date()).format('yyyy-MM-DD')}
        axios.post('/Order/PlaceVendorOrder', vendorData).then(res => {
            if (res.data.message === 'Success') {
                setCustomOrderRender({customOrderRender: customOrderRender + 1})
                hideVendorConfirmationModal()
                hideCustomOrderDetailModal()
            }
        })
    }
    return (
        <div>
            <Modal show={customOrderDetailModalVisible} size={'lg'} centered={true}>
            {/* hideCustomOrderDetailModal */}
                <Modal.Header style={{padding: '15px 10px 15px 10px'}}>
                    <div class="row w-100 position-relative">
                        <div class="col-md-4 col-6 pe-3 d-flex align-items-center fs-5">
                            Detail
                        </div>
                        <div class="col-md-8 col-6 d-flex align-items-center justify-content-end">
                            <button class="btn btn-sm btn-secondary me-1 text-nowrap" onClick={() => {handlePrintBill()}}><ion-icon name="print-outline"></ion-icon> Print Bill</button>
                            <button class="btn btn-sm btn-primary text-nowrap" onClick={() => handleCheckboxModal()}><ion-icon name="glasses-outline"></ion-icon> Share Measurments</button>
                            <a href="javascript:void(0)" class="d-flex" onClick={() => {closeModal()}}>
                                <ion-icon name="close-outline" class="fs-1 me-0"></ion-icon>
                            </a>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body class="pt-0 p-1">
                    <div class="modal-body p-0 mt-1">
                        <div class="overflow-scroll">
                            <div class="row">
                                <div class="col-2 col-md-2 d-flex justify-content-center">
                                    <a href={`tel:${customOrderDetailData?.Phone}`} class="bg-info d-flex align-items-center justify-content-center rounded-circle p-0 position-relative cursor-pointer" style={{height: '58px', width: '58px'}}>
                                        <ion-icon name="call-outline" class="fs-2"></ion-icon>
                                    </a>
                                </div>
                                <div class="col-2 col-md-2 d-flex justify-content-center">
                                    <div class="bg-success d-flex align-items-center justify-content-center rounded-circle p-0 position-relative cursor-pointer" onClick={() => {shareBillMessage('whatsapp')}} style={{height: '58px', width: '58px'}}>
                                        <ion-icon name="logo-whatsapp" class="fs-2"></ion-icon>
                                    </div>
                                </div>
                                <div class="col-2 col-md-2 d-flex justify-content-center">
                                    <div class="bg-warning d-flex align-items-center justify-content-center rounded-circle p-0 position-relative cursor-pointer" onClick={() => {shareBillMessage('sms')}} style={{height: '58px', width: '58px'}}>
                                        <ion-icon name="chatbox-ellipses-outline" class="fs-2"></ion-icon>
                                    </div>
                                </div>
                                <div class="col-2 col-md-2 d-flex justify-content-center">
                                    <div class="bg-secondary d-flex align-items-center justify-content-center rounded-circle p-0 position-relative cursor-pointer" onClick={() => {handleImagePdf()}} style={{height: '58px', width: '58px'}}>
                                        <ion-icon name="share-social-outline" class="fs-2"></ion-icon>
                                    </div>
                                </div>
                                <div class="col-2 col-md-2 d-flex justify-content-center">
                                    <div class="bg-primary d-flex align-items-center justify-content-center rounded-circle p-0 position-relative cursor-pointer" onClick={() => {orderPlaceMessage()}} style={{height: '58px', width: '58px'}}>
                                        <ion-icon name="checkmark-outline" class="fs-2"></ion-icon>
                                    </div>
                                </div>
                                <div class="col-2 col-md-2 d-flex justify-content-center">
                                    <div class="bg-success d-flex align-items-center justify-content-center rounded-circle p-0 position-relative cursor-pointer" onClick={() => {orderReadyMessage()}} style={{height: '58px', width: '58px'}}>
                                        <ion-icon name="checkmark-done-outline" class="fs-2"></ion-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="border p-1">
                            <div class="row">
                                <div class="col-3 col-md-3 d-flex align-items-center">
                                    <img src={ShreeOptics} width="100x100" />
                                </div>
                                <div class="col-3 col-md-6 d-flex align-items-center justify-content-start flex-column">
                                    <h3 class="mb-1">Invoice No: <span class="fw-bold">{customOrderDetailData?.BillNo}</span></h3>
                                    <h4 class="mb-1"><span>{customOrderDetailData?.Name} ({customOrderDetailData?.Age})</span></h4>
                                    <h4 class="mb-1"><Moment format={'DD/MM/YYYY'}>{customOrderDetailData?.OrderDate}</Moment></h4>
                                </div>
                                <div class="col-6 col-md-3 d-flex align-items-end justify-content-end flex-column overflow-wrap">
                                    <h4 class="mb-1"><a href={"tel:" + customOrderDetailData?.Phone}>{customOrderDetailData?.Phone}</a></h4>
                                    <h4 class="mb-1"><a href={"mailto:" + customOrderDetailData?.OrderDetails?.Email}>{customOrderDetailData?.OrderDetails?.Email}</a></h4>
                                    <h4 class="mb-1"><span>{customOrderDetailData?.OrderDetails?.Address}</span></h4>
                                </div>
                            </div>
                            <div class="section full mt-1">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="fw-bold text-secondary border-top" style={{fontSize: '12px', borderBottom: '1px solid #333333'}}>ITEM</th>
                                                <th scope="col" class="fw-bold text-secondary border-top" style={{fontSize: '12px', borderBottom: '1px solid #333333'}}>DESCRIPTION</th>
                                                <th scope="col" class="fw-bold text-secondary border-top text-end" style={{fontSize: '12px', borderBottom: '1px solid #333333'}}>RATE</th>
                                                <th scope="col" class="fw-bold text-secondary border-top text-end" style={{fontSize: '12px', borderBottom: '1px solid #333333'}}>QTY</th>
                                                <th scope="col" class="fw-bold text-secondary border-top text-end" style={{fontSize: '12px', borderBottom: '1px solid #333333'}}>SUBTOTAL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {customOrderDetailData?.Items.map((v,k) => {
                                                return <>
                                                    {v.CustomSIze &&
                                                        <tr style={{border: 'none'}}>
                                                            <td>{v.ItemTitle}</td>
                                                            <td>{v.CustomSIze}</td>
                                                            <td class="text-end">₹{v.MRP}</td>
                                                            <td class="text-end">{v.quantity}</td>
                                                            <td class="text-end">₹{v.quantity * v.MRP}</td>
                                                        </tr>
                                                    }
                                                </>
                                            })}
                                            <tr style={{border: 'transparent'}}>
                                                <td style={{visibility: 'hidden'}}></td>
                                                <td style={{visibility: 'hidden'}}></td>
                                                <td class="border-bottom" colSpan={2}>Subtotal</td>
                                                <td class="text-end border-bottom">₹{customOrderDetailData?.Subtotal}</td>
                                            </tr>
                                            <tr style={{border: 'transparent'}}>
                                                <td style={{visibility: 'hidden'}}></td>
                                                <td style={{visibility: 'hidden'}}></td>
                                                <td class="text-success border-bottom" colSpan={2}>Discount</td>
                                                <td class="text-end  text-success border-bottom">₹{customOrderDetailData?.TotalDiscount}</td>
                                            </tr>
                                            <tr style={{border: 'transparent'}}>
                                                <td style={{visibility: 'hidden'}}></td>
                                                <td style={{visibility: 'hidden'}}></td>
                                                <td class="text-primary border-bottom" colSpan={2}>Total</td>
                                                <td class="text-end text-primary border-bottom">₹{customOrderDetailData?.GrandTotal}</td>
                                            </tr>
                                            <tr style={{border: 'transparent'}}>
                                                <td style={{visibility: 'hidden'}}></td>
                                                <td style={{visibility: 'hidden'}}></td>
                                                <td class="border-bottom" colSpan={2}>Advance</td>
                                                <td class="text-end border-bottom">₹{customOrderDetailData?.Advance}</td>
                                            </tr>
                                            <tr style={{border: 'transparent'}}>
                                                <td style={{visibility: 'hidden'}}></td>
                                                <td style={{visibility: 'hidden'}}></td>
                                                <td class="text-dark" colSpan={2}>Balance</td>
                                                <td class="text-end text-dark">₹{customOrderDetailData?.Balance}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="mt-2 border p-1">
                            {customOrderDetailData?.OrderDetails?.ReferenceImage?.map((v,k) => {
                                return <img src={v} width="70px" class="me-1 cursor-pointer" alt="Reference Image" key={k} style={{maxHeight: '70px'}} onClick={() => {handleFullImage(v)}} />
                            })}
                        </div>
                        <div>
                            <div class="mt-2 p-1" id="bothTable">
                                <div class="section full mt-1" id="table1" style={paddingStyle}>
                                    <h2 class="mt-2 text-center">Glasses Prescription</h2>
                                    <h3 class="text-center">Right</h3>
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="border-end border-top border-start"></th>
                                                    <th scope="col" class="text-center border-end border-top fw-bold">SPH</th>
                                                    <th scope="col" class="text-center border-end border-top fw-bold">CYL</th>
                                                    <th scope="col" class="text-center border-end border-top fw-bold">AXIS</th>
                                                    <th scope="col" class="text-center border-end border-top fw-bold">VISION</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row" class="border-end border-start">Dist</th>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.RightTable?.rsphd) ? customOrderDetailData?.OrderDetails?.RightTable?.rsphd : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.RightTable?.rcyld) ? customOrderDetailData?.OrderDetails?.RightTable?.rcyld : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.RightTable?.raxisd) ? customOrderDetailData?.OrderDetails?.RightTable?.raxisd : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.RightTable?.rvisiond) ? customOrderDetailData?.OrderDetails?.RightTable?.rvisiond : '-'}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="border-end border-start">Near</th>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.RightTable?.rsphn) ? customOrderDetailData?.OrderDetails?.RightTable?.rsphn : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.RightTable?.rcyln) ? customOrderDetailData?.OrderDetails?.RightTable?.rcyln : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.RightTable?.raxisn) ? customOrderDetailData?.OrderDetails?.RightTable?.raxisn : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.RightTable?.rvisionn) ? customOrderDetailData?.OrderDetails?.RightTable?.rvisionn : '-'}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="border-end border-start">Add</th>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.RightTable?.rspha) ? customOrderDetailData?.OrderDetails?.RightTable?.rspha : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.RightTable?.rcyla) ? customOrderDetailData?.OrderDetails?.RightTable?.rcyla : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.RightTable?.raxisa) ? customOrderDetailData?.OrderDetails?.RightTable?.raxisa : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.RightTable?.rvisiona) ? customOrderDetailData?.OrderDetails?.RightTable?.rvisiona : '-'}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <h3 class="text-center mt-2">Left</h3>
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="border-end border-top border-start"></th>
                                                    <th scope="col" class="text-center border-end border-top fw-bold">SPH</th>
                                                    <th scope="col" class="text-center border-end border-top fw-bold">CYL</th>
                                                    <th scope="col" class="text-center border-end border-top fw-bold">AXIS</th>
                                                    <th scope="col" class="text-center border-end border-top fw-bold">VISION</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row" class="border-end border-start">Dist</th>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LeftTable?.lsphd) ? customOrderDetailData?.OrderDetails?.LeftTable?.lsphd : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LeftTable?.lcyld) ? customOrderDetailData?.OrderDetails?.LeftTable?.lcyld : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LeftTable?.laxisd) ? customOrderDetailData?.OrderDetails?.LeftTable?.laxisd : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LeftTable?.lvisiond) ? customOrderDetailData?.OrderDetails?.LeftTable?.lvisiond : '-'}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="border-end border-start">Near</th>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LeftTable?.lsphn) ? customOrderDetailData?.OrderDetails?.LeftTable?.lsphn : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LeftTable?.lcyln) ? customOrderDetailData?.OrderDetails?.LeftTable?.lcyln : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LeftTable?.laxisn) ? customOrderDetailData?.OrderDetails?.LeftTable?.laxisn : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LeftTable?.lvisionn) ? customOrderDetailData?.OrderDetails?.LeftTable?.lvisionn : '-'}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="border-end border-start">Add</th>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LeftTable?.lspha) ? customOrderDetailData?.OrderDetails?.LeftTable?.lspha : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LeftTable?.lcyla) ? customOrderDetailData?.OrderDetails?.LeftTable?.lcyla : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LeftTable?.laxisa) ? customOrderDetailData?.OrderDetails?.LeftTable?.laxisa : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LeftTable?.lvisiona) ? customOrderDetailData?.OrderDetails?.LeftTable?.lvisiona : '-'}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="section full mt-1" id="table2" style={paddingStyle}>
                                    <h2 class="mt-2 text-center">Contact Lenses</h2>
                                    <h3 class="text-center">Right</h3>
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="border-end border-top border-start"></th>
                                                    <th scope="col" class="text-center border-end border-top fw-bold">SPH</th>
                                                    <th scope="col" class="text-center border-end border-top fw-bold">CYL</th>
                                                    <th scope="col" class="text-center border-end border-top fw-bold">AXIS</th>
                                                    <th scope="col" class="text-center border-end border-top fw-bold">VISION</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row" class="border-end border-start">Dist</th>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LensesRightTable?.rsphd) ? customOrderDetailData?.OrderDetails?.LensesRightTable?.rsphd : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LensesRightTable?.rcyld) ? customOrderDetailData?.OrderDetails?.LensesRightTable?.rcyld : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LensesRightTable?.raxisd) ? customOrderDetailData?.OrderDetails?.LensesRightTable?.raxisd : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LensesRightTable?.rvisiond) ? customOrderDetailData?.OrderDetails?.LensesRightTable?.rvisiond : '-'}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="border-end border-start">Near</th>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LensesRightTable?.rsphn) ? customOrderDetailData?.OrderDetails?.LensesRightTable?.rsphn : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LensesRightTable?.rcyln) ? customOrderDetailData?.OrderDetails?.LensesRightTable?.rcyln : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LensesRightTable?.raxisn) ? customOrderDetailData?.OrderDetails?.LensesRightTable?.raxisn : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LensesRightTable?.rvisionn) ? customOrderDetailData?.OrderDetails?.LensesRightTable?.rvisionn : '-'}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="border-end border-start">Add</th>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LensesRightTable?.rspha) ? customOrderDetailData?.OrderDetails?.LensesRightTable?.rspha : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LensesRightTable?.rcyla) ? customOrderDetailData?.OrderDetails?.LensesRightTable?.rcyla : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LensesRightTable?.raxisa) ? customOrderDetailData?.OrderDetails?.LensesRightTable?.raxisa : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LensesRightTable?.rvisiona) ? customOrderDetailData?.OrderDetails?.LensesRightTable?.rvisiona : '-'}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <h3 class="text-center mt-2">Left</h3>
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="border-end border-top border-start"></th>
                                                    <th scope="col" class="text-center border-end border-top fw-bold">SPH</th>
                                                    <th scope="col" class="text-center border-end border-top fw-bold">CYL</th>
                                                    <th scope="col" class="text-center border-end border-top fw-bold">AXIS</th>
                                                    <th scope="col" class="text-center border-end border-top fw-bold">VISION</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row" class="border-end border-start">Dist</th>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LensesLeftTable?.lsphd) ? customOrderDetailData?.OrderDetails?.LensesLeftTable?.lsphd : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LensesLeftTable?.lcyld) ? customOrderDetailData?.OrderDetails?.LensesLeftTable?.lcyld : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LensesLeftTable?.laxisd) ? customOrderDetailData?.OrderDetails?.LensesLeftTable?.laxisd : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LensesLeftTable?.lvisiond) ? customOrderDetailData?.OrderDetails?.LensesLeftTable?.lvisiond : '-'}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="border-end border-start">Near</th>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LensesLeftTable?.lsphn) ? customOrderDetailData?.OrderDetails?.LensesLeftTable?.lsphn : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LensesLeftTable?.lcyln) ? customOrderDetailData?.OrderDetails?.LensesLeftTable?.lcyln : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LensesLeftTable?.laxisn) ? customOrderDetailData?.OrderDetails?.LensesLeftTable?.laxisn : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LensesLeftTable?.lvisionn) ? customOrderDetailData?.OrderDetails?.LensesLeftTable?.lvisionn : '-'}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" class="border-end border-start">Add</th>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LensesLeftTable?.lspha) ? customOrderDetailData?.OrderDetails?.LensesLeftTable?.lspha : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LensesLeftTable?.lcyla) ? customOrderDetailData?.OrderDetails?.LensesLeftTable?.lcyla : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LensesLeftTable?.laxisa) ? customOrderDetailData?.OrderDetails?.LensesLeftTable?.laxisa : '-'}</td>
                                                    <td class="text-center border-end">{(customOrderDetailData?.OrderDetails?.LensesLeftTable?.lvisiona) ? customOrderDetailData?.OrderDetails?.LensesLeftTable?.lvisiona : '-'}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {imagePath &&
                        <div class="d-flex align-items-center justify-content-center flex-column" style={{position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#333333c2', zIndex: 999}}>
                            <div class="w-100 text-end pe-3 cursor-pointer" onClick={() => {handleFullImage()}}>
                                <ion-icon name="close" class="text-white fs-1"></ion-icon>
                            </div>
                            <TransformWrapper>
                                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                <>
                                    <TransformComponent>
                                        <img src={imagePath} alt="test" width="400px" />
                                    </TransformComponent>
                                </>
                                )}
                            </TransformWrapper>
                            {/* <img src={imagePath} width="400px" /> */}
                        </div>
                    }
                    <CheckboxModal></CheckboxModal>
                </Modal.Body>
            </Modal>
            <Modal show={vendorConfirmationModalVisible} backdrop="static" size={'sm'} centered={true}>
            {/* hideCustomOrderDetailModal */}
                <Modal.Body class="p-2">
                    <h2 class="text-center">Shared a Prescription to Vendor ?</h2>
                    <div class="d-flex justify-content-center">
                        <button class="btn btn-link btn-outline-success text-success btn-lg me-2" onClick={() => {sharedToVendorConfirm()}}>Yes</button>
                        <button class="btn btn-link btn-outline-danger text-danger btn-lg" onClick={() => {closeFirstConfirmationModal()}}>No</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

const mapStateToProps = ({ customOrderDetail, data, confirmation, auth }) => {
    const {
        customOrderDetailModalVisible,
        customOrderDetailData
    } = customOrderDetail
    const {
        prescriptionData,
        customOrderRender,
        vendorMessage,
        bluetoothStatus
    } = data
    const {
        vendorConfirmationModalVisible
    } = confirmation
    const {
        buildType
    } = auth
    return {
        customOrderDetailModalVisible,
        customOrderDetailData,
        prescriptionData,
        vendorConfirmationModalVisible,
        customOrderRender,
        vendorMessage,
        buildType,
        bluetoothStatus
    }
  }
  
  const mapDispatchToProps = {
    hideCustomOrderDetailModal,
    setOpticPrintBill,
    showCheckboxModal,
    showImagePdfModal,
    showVendorConfirmationModal,
    hideVendorConfirmationModal,
    setCustomOrderRender,
    showPrinterSettingModal
  }

export default connect(mapStateToProps, mapDispatchToProps)(CustomOrderDetailModal)