import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setKotData, setDefaultPrinterName } from '../../../features/Data/dataSlice';
import { showPrinterSettingModal } from '../../../features/Offcanvas/printerSettingModalSlice';

const PrintKot = ({
    kotData,
    setKotData,
    buildType,
    bluetoothStatus,
    defaultPrinterName,
    showPrinterSettingModal,
    setDefaultPrinterName
}) => {
    function printKotIframe(data) {
        console.log(data)
        console.log('Call back function')
        var iframe = document.getElementById('myKotIframe');
        // border-right: 1px solid #333333;border-bottom: 1px solid #333333;
        let tdDom1 = ''
        data?.Items.map((v,k) => {
            tdDom1 += '<tr><td>' + v.ItemTitle + '</td><td style="text-align: end;">' + v.quantity + '</td></td>'
        })
        
        const PrintKotReceipt = "<div id='printKotReceipt'><body data-new-gr-c-s-check-loaded='14.1121.0' data-gr-ext-installed=''><font face='verdana' size='2'><div><div><div id='orderTicketDetails'><div class='orderTicket' style='display: block;'><div style='page-break-after:always;'><div style='font-size:12px; display:flex; justify-content: center; font-weight: bold;'>KOT - " + data?.kotNumber + "</div><div style='font-size:12px; text-align: center; font-weight: bold;'> " + data?.Type + "</div><div style='font-size:12px; text-align:center; font-weight: bold;'><Moment format='DD-MM-YYYY HH:mm'>" + data?.DateTime + "</Moment></div><hr /><table style='width:100%;'><thead><tr><th align='left' style='font-size: 14px;'> ITEM </th><th align='right' style='font-size: 14px;'> QTY </th></tr></thead><tbody>" + tdDom1 + "</tbody></table><hr /><br /></div></div></div></div></div></font></body></div>";
        
        iframe.contentWindow.document.body.innerHTML = PrintKotReceipt

        if (buildType === 'webapk') {
            console.log(data)
            iframe.onload = function() {
                iframe.contentWindow.print();
            };
    
            if (iframe.contentDocument.readyState === 'complete') {
                iframe.contentWindow.print();
            }
        } else {
            var plainText = extractTextFromHTML(PrintKotReceipt);
            if (bluetoothStatus && defaultPrinterName) {
                const tempData = data
                window.BTPrinter.connect(function(data){
                    // window.BTPrinter.printTextSizeAlign(function(data1){
                    //     console.log("printTextSizeAlign Success", data1);
                    //     window.BTPrinter.disconnect(function(data){
                    //         console.log("Success");
                    //         console.log(data)
                    //     },function(err){
                    //         console.log("Error");
                    //         console.log(err)
                    //     }, defaultPrinterName);
                    // },function(err){
                    //     window.BTPrinter.disconnect(function(data){
                    //         console.log("Success");
                    //         console.log(data)
                    //     },function(err){
                    //         console.log("Error");
                    //         console.log(err)
                    //     }, defaultPrinterName);
                    //     console.log("Error");
                    //     console.log(err)
                    // }, plainText,'0','0')

                    window.BTPrinter.printTextSizeAlign(function(data) {
                        console.log("Success");
                    }, function(err) {
                        console.log("Error");
                    }, `KOT: ${tempData.kotNumber}`, '1', '1'); // Kitchen Order title

                    window.BTPrinter.printTextSizeAlign(function(data) {
                        console.log("Success");
                    }, function(err) {
                        console.log("Error");
                    }, `${tempData.Type}`, '1', '1'); // Kitchen Order title

                    window.BTPrinter.printTextSizeAlign(function(data) {
                        console.log("Success");
                    }, function(err) {
                        console.log("Error");
                    }, "-----------------------------------------", '0', '1'); // Divider line

                    // Print product table headers
                    window.BTPrinter.printTextSizeAlign(function(data) {
                        console.log("Success");
                    }, function(err) {
                        console.log("Error");
                    }, "Item                Qty", '0', '0'); // Column headers

                    window.BTPrinter.printTextSizeAlign(function(data) {
                        console.log("Success");
                    }, function(err) {
                        console.log("Error");
                    }, "-----------------------------------------", '0', '1'); // Divider line

                    // Loop through the KOT products array and print each product's details
                    tempData.Items.map((v,k) => {
                        window.BTPrinter.printTextSizeAlign(function(data) {
                            console.log("Success");
                        }, function(err) {
                            console.log("Error");
                        }, `${v.ItemTitle}`, '0', '0'); // Print each product line

                        window.BTPrinter.printTextSizeAlign(function(data) {
                            console.log("Success");
                        }, function(err) {
                            console.log("Error");
                        }, `                     ${v.quantity}`, '0', '0'); // Print each product line
                    });

                    window.BTPrinter.printTextSizeAlign(function(data) {
                        console.log("Success");
                    }, function(err) {
                        console.log("Error");
                    }, "-----------------------------------------", '0', '1'); // Divider line after products

                    // Print footer message (optional)
                    window.BTPrinter.printTextSizeAlign(function(data) {
                        console.log("Success");
                    }, function(err) {
                        console.log("Error");
                    }, "Thank you! Please prepare.", '0', '1'); // Footer message

                },function(err){
                    console.log("Error");
                    console.log(err)
                    setDefaultPrinterName({defaultPrinterName: null})
                    localStorage.removeItem('defaultPrinter')
                    showPrinterSettingModal({printerSettingModalVisible: true})
                }, defaultPrinterName);
            } else {
                if (!bluetoothStatus) {
                    alert('Your bluetooth is off')
                } else if (bluetoothStatus && (!defaultPrinterName)) {
                    showPrinterSettingModal({printerSettingModalVisible: true})
                }
            }
        }
    }

    function extractTextFromHTML(htmlString) {
        // Create a temporary DOM element
        var tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;
    
        // Extract all text content
        var output = '';
    
        // Extract business name, branch name, and invoice details
        var businessName = tempDiv.querySelector('#receiptDetails > div:nth-child(2)');
        var branchName = tempDiv.querySelector('#receiptDetails > div:nth-child(3)');
        var billNo = tempDiv.querySelector('#receiptDetails > div:nth-child(4)');
        var orderType = tempDiv.querySelector('#receiptDetails > div:nth-child(5)');
        var orderDate = tempDiv.querySelector('#receiptDetails > div:nth-child(6)');
    
        output += (businessName ? businessName.textContent.trim() : '') + '\n';
        output += (branchName ? branchName.textContent.trim() : '') + '\n';
        output += (billNo ? billNo.textContent.trim() : '') + '\n';
        output += (orderType ? orderType.textContent.trim() : '') + '\n';
        output += (orderDate ? orderDate.textContent.trim() : '') + '\n\n';
    
        // Find the table and extract data
        var table = tempDiv.querySelector('table');
        if (table) {
            var rows = table.rows;
            for (var i = 0; i < rows.length; i++) {
                var cells = rows[i].cells;
                for (var j = 0; j < cells.length; j++) {
                    output += cells[j].textContent.trim() + '\t'; // Use tab for spacing between columns
                }
                output += '\n'; // New line for each row
            }
            output += '\n'; // Extra line break after the table
        } else {
            console.error('No table found in the HTML string');
        }
    
        // Extract subtotal, discount, tax, grand total from the table below
        var rows = tempDiv.querySelectorAll('tr'); // Get all table rows
        rows.forEach(function (row) {
            var cells = row.cells;
            if (cells.length === 2) {
                var label = cells[0].textContent.trim();
                var value = cells[1].textContent.trim();
                if (label === 'Subtotal') {
                    output += 'Subtotal: ' + value + '\n';
                } else if (label.startsWith('Discount')) {
                    output += label + ': ' + value + '\n'; // Handles 'Discount (X%)'
                } else if (label === 'Grand Total') {
                    output += 'Grand Total: ' + value + '\n';
                }
            }
        });
    
        // Extract footer message
        var footer = tempDiv.querySelector('div[style*="text-align: center"]');
        output += '\n' + (footer ? footer.textContent.trim() : '') + '\n';
    
        return output;
    }

    useEffect(() => {
        if (kotData) {
            console.log('KOT PRINT')
            printKotIframe(kotData)
            setKotData({kotData: null, printFrom: ''}) 
        }
    }, [kotData])
    return (
        <iframe id="myKotIframe" style={{display: 'none'}}></iframe> 
    );
};

const mapStateToProps = ({ data, auth }) => {
    const {
        kotData,
        bluetoothStatus,
        defaultPrinterName
    } = data
    const {
        buildType
    } = auth
    return {
        kotData,
        buildType,
        bluetoothStatus,
        defaultPrinterName
    }
}
  
const mapDispatchToProps = {
    setKotData,
    showPrinterSettingModal,
    setDefaultPrinterName
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintKot);